.z-index-10 {
    z-index: 1000;
}

.rgt-cell-header {
    color: #3880ff;
}

.rgt-footer-pagination {
    color: #3880ff;
}

.rgt-search-label {
    color: #3880ff;
}

.rgt-header-container {
    margin-bottom: 1.5em;
    border: 2px solid #3880ff;
    border-radius: 1em;
}

/* Set font and font size */
body {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
}

/* Style table headers */
.ReactGridTable__header-column {
    font-weight: bold;
    text-transform: uppercase;
    background-color: #f4f4f4;
    padding: 12px;
    border: 1px solid #ccc;
}

/* Style table cells */
.ReactGridTable__cell {
    padding: 10px;
    border: 1px solid #ccc;
}

/* Style table rows */
.ReactGridTable__row:nth-child(odd) {
    background-color: #f9f9f9;
}

/* Style table hover */
.ReactGridTable__row:hover {
    background-color: #f4f4f4;
}

/* rgt-cell-header rgt-cell-header-email rgt-clickable  rgt-cell-header-sticky rgt-cell-header-resizable rgt-cell-header-searchable */